export class DateFormats {

    // see https://date-fns.org/v3.6.0/docs/format
    // EEEE	Monday, Tuesday, ..., Sunday
    // PPP	April 29th, 1453
    // p	12:00 AM
    // yyyy-MM-dd	1999-12-31

    public static readonly long = "EEEE PPP";
    public static readonly standard = "PPP";
    public static readonly standardWithTime = "PPP @ p";
    public static readonly yearMonthDay = "yyyy-MM-dd";
    public static readonly monthDayYear = "MM/dd/yyyy";
    public static readonly yearMonthDaySlash = "yyyy/MM/dd";
    public static readonly longWithTime = "PPP, p";
    public static readonly timeOnly = "p"

    public static formats = [
        DateFormats.long,
        DateFormats.standard,
        DateFormats.yearMonthDay,
        DateFormats.monthDayYear,
        DateFormats.yearMonthDaySlash,
        DateFormats.standardWithTime,
        DateFormats.longWithTime,
        DateFormats.timeOnly
    ];

    public static formatUtcDate(date: Date, format: string): string {
        if (!(date instanceof Date) || isNaN(date.getTime())) {
            try {
                date = new Date(date);
            } catch {
                throw new Error(`Invalid Date: ${date}`);
            }
        }

        const options: Intl.DateTimeFormatOptions = {};

        switch (format) {
            case DateFormats.standard:
            case "PPP":
                options.year = "numeric";
                options.month = "long";
                options.day = "numeric";
                break;
            case DateFormats.yearMonthDay:
            case "yyyy-MM-dd":
                return date.toISOString().split("T")[0];
            case DateFormats.monthDayYear:
            case "MM/dd/yyyy":
                options.year = "numeric";
                options.month = "2-digit";
                options.day = "2-digit";
                break;
            default:
                throw new Error(`Unsupported format: ${format}`);
        }

        return date.toLocaleDateString("en-US", { ...options, timeZone: "UTC" });
    }

    public static formatUtcDateToLocal(date: Date): string {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
    }

    // Some date formats, specifically for Date questions are stored in the database, in large json blobs and cannot easily be changed.
    // The purpose of this function is to convert those formats to ones that work according to this:
    // https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md    
    public static ConvertCapitalDateFormat(format: string): string {
        return format.replace("DD", "dd").replace("YYYY", "yyyy");
    }
} 
