import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { Logo } from "./Logo";
import { Mobile } from "./Mobile";
import { ReportsNav } from "./ReportsNav";
import { SubMenu } from "./SubMenu";
import { UserInfo } from "./UserInfo";
import { NavbarLink } from "./types";
import { checkCurrentUrl } from "./utils";

type DistrictHeaderProps = {
    hasProcessTemplatesFeature: boolean;
    hasDistrictFormsFeature: boolean;
    hasDownloadResponsesFeature: boolean;
    isPermissionClick: boolean;
    isDistrictAdministrator: boolean;
    isCanadianOrganization: boolean;
    homeUri: string;
    logoUri: string;
    dashboardUri: string;
    myApprovalsUri: string;
    processOverviewUri: string;
    formsUri: string;
    templatesUri: string;
    createOrganizationUri: string;
    manageUsersUri: string;
    editOrganizationUri: string;
    brandingTextUri: string;
    gstReportUri: string;
    responseExporterUri: string;
    districtName: string;
    organizationName: string;
    userName: string;
    userRoleName: string;
    organizationLogoUri: string;
    email: string;
    editionName: string;
    rosterManagementUri: string;
    integratedDataManagementUri: string;
    hasIntegrationManagementAccess: boolean;
    integrationManagementUri: string;
    hasActiveDirectoryManagementAccess: boolean;
    activeDirectoryManagementUri: string;
    logoutUri: string;
}

export const DistrictHeader = (props: DistrictHeaderProps) => {
    const location = useLocation();
    const currentUri = location.pathname;
    const currentHash = location.hash;
    const isActiveLink = checkCurrentUrl({ currentUri, currentHash });

    const links: NavbarLink[] = [
        {
            label: "Approvals",
            url: props.myApprovalsUri,
            enabled: true,
        },
        {
            label: "Forms",
            url: props.formsUri,
            enabled: props.hasDistrictFormsFeature,
        },
        {
            label: "Processes",
            url: props.processOverviewUri,
            enabled: props.hasProcessTemplatesFeature && (props.isDistrictAdministrator || props.isPermissionClick),
        },
        {
            label: "Templates",
            url: props.templatesUri,
            enabled: true,
        },
        {
            label: "Reports",
            component: <ReportsNav key="Reports" isActiveLink={isActiveLink} />,
            enabled: true,
        },
        {
            label: "Admin",
            enabled: props.isPermissionClick || props.isDistrictAdministrator,
            children: [
                {
                    label: "Create Account",
                    url: props.createOrganizationUri,
                    enabled: true,
                },
                {
                    label: "User Management",
                    url: props.manageUsersUri,
                    enabled: true,
                },
                {
                    label: "Edit Organization",
                    url: props.editOrganizationUri,
                    enabled: true,
                },
                {
                    label: "Customize Role Labels",
                    url: props.brandingTextUri,
                    enabled: true,
                },
                {
                    label: 'Email Response Documents',
                    url: props.responseExporterUri,
                    enabled: props.hasDownloadResponsesFeature,
                },
                {
                    label: 'Integration Settings and History',
                    url: props.integrationManagementUri,
                    enabled: props.hasIntegrationManagementAccess,
                },
                {
                    label: "Roster",
                    url: props.rosterManagementUri,
                    enabled: props.hasIntegrationManagementAccess && !!props.rosterManagementUri,
                },
                {
                    label: "Integrated Data Management",
                    url: props.integratedDataManagementUri,
                    enabled: props.hasIntegrationManagementAccess && !!props.integratedDataManagementUri,
                },
                {
                    label: "Active Directory Management",
                    url: props.activeDirectoryManagementUri,
                    enabled: props.hasActiveDirectoryManagementAccess,
                }
            ],
        },
    ];

    return (
        <nav className="district-header">
            <ul className="layout-content">

                <Mobile
                    logoUri={props.logoUri}
                    organizationLogoUri={props.organizationLogoUri}
                    organizationName={props.organizationName}
                    logoutUri={props.logoutUri}
                    links={links}
                />
                <li className="logo">
                    <Link className="nav-home" to={props.homeUri} title="Home">
                        <Logo
                            logoUri={props.logoUri}
                        />
                    </Link>
                </li>

                {links.filter(({ enabled }) => enabled).map(link =>
                    link.children && link.children.length
                        ? (
                            <SubMenu label={link.label} links={link.children} key={link.label} />
                        )
                        : link.component
                            ? link.component
                            : (
                                <li key={link.label} className="menu">
                                    <span className="notification-wrapper">
                                        {link.url && link.url.charAt(0) === "/" ?
                                            <Link className={`district-dashboard nav-link ${isActiveLink(link.url) ? 'active' : ''}`} to={link.url}>{link.label}</Link>
                                            : <a
                                                className={`district-dashboard nav-link ${isActiveLink(link.url) ? 'active' : ''}`}
                                                href={link.url}
                                            >
                                                {link.label}
                                            </a>
                                        }
                                        {
                                            link.showNotification ? (
                                                <span
                                                    className="circle notification"
                                                    aria-label="There are pending approvals"
                                                    tabIndex={0}
                                                ></span>
                                            ) : null
                                        }
                                    </span>
                                </li>
                            )
                )}

                <UserInfo
                    districtName={props.districtName}
                    organizationName={props.organizationName}
                    userName={props.userName}
                    userRoleName={props.userRoleName}
                    organizationLogoUri={props.organizationLogoUri}
                    showDistrictName={false}
                    email={props.email}
                    dashboardUri={props.dashboardUri}
                    isPermissionClick={props.isPermissionClick}
                    isDistrictAdministrator={props.isDistrictAdministrator}
                    editionName={props.editionName}
                />
            </ul>

        </nav>
    );
}
